import { Modal } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Moment } from 'moment';
import { useState } from 'react';
import Calendar from './Calendar';

export type Props = {
  startDate: Moment | null;
  endDate: Moment | null;
  setStartDate: React.Dispatch<React.SetStateAction<Moment | null>>;
  setEndDate: React.Dispatch<React.SetStateAction<Moment | null>>;
  searchClick: () => void;
};

const Form = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  searchClick,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');

  const openCalendarModal = () => {
    setModalOpen(true);
  };

  const closeCalendarModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="searching-form">
      <div className="searching-checkin">
        <input
          className="searching-checkin-input"
          type="text"
          readOnly
          placeholder="Check-in Date"
          value={startDate?.format('MMM DD, YYYY') ?? ''}
          onClick={openCalendarModal}
        />
      </div>
      <div className="searching-checkout">
        <input
          className="searching-checkout-input"
          type="text"
          readOnly
          placeholder="Check-out Date"
          value={endDate?.format('MMM DD, YYYY') ?? ''}
          onClick={openCalendarModal}
        />
      </div>
      <button onClick={searchClick} className="searching-button">
        Search
      </button>
      <Modal
        opened={modalOpen}
        onClose={closeCalendarModal}
        size="auto"
        fullScreen={isMobile}
        styles={{
          modal: { display: 'flex', flexDirection: 'column' },
          body: { flex: '1' },
        }}
      >
        <Calendar
          isMobile={isMobile}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          closeModal={closeCalendarModal}
          searchClick={searchClick}
        />
      </Modal>
    </div>
  );
};

export default Form;
