import { Pagination } from '@mantine/core';
import { useEffect, useState } from 'react';
import { IProperty } from '../utils/types';
import Property from './Property';

export type Props = {
  properties: IProperty[];
  activePage: number;
  setActivePage: React.Dispatch<React.SetStateAction<number>>;
};

const PaginatedProperties = ({
  properties,
  activePage,
  setActivePage,
}: Props) => {
  const pageSize = 10;
  const total = Math.ceil(properties.length / pageSize);
  const [shownProperties, setShownProperties] = useState(
    properties.slice(0, pageSize)
  );

  const changePage = (page: number) => {
    setActivePage(page);
  };

  useEffect(() => {
    const minIndex = (activePage - 1) * pageSize;
    const maxIndex = minIndex + pageSize;
    setShownProperties(properties.slice(minIndex, maxIndex));
  }, [activePage, properties]);

  useEffect(() => {
    const mapProperties = shownProperties.map((property) => ({
      propid: property.propid,
      latlong: property.latlong,
      propname: property.propname,
      shortname: property.shortname,
      shortdesc: property.shortdesc,
    }));

    window.mapProperties = mapProperties;
    const event = new CustomEvent('propertyChange', { detail: mapProperties });
    document.dispatchEvent(event);
  }, [shownProperties]);

  return (
    <>
      <div className="property-list">
        {shownProperties.map((property) => (
          <Property key={property.propid} property={property} />
        ))}
      </div>
      <div className="widget-pagination-container">
        <Pagination page={activePage} onChange={changePage} total={total} />
      </div>
    </>
  );
};

export default PaginatedProperties;
