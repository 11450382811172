import { Modal, Select, TextInput } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Moment } from 'moment';
import { useEffect, useState } from 'react';
import Calendar from './Calendar';

export type Props = {
  originalStartDate: Moment | null;
  originalEndDate: Moment | null;
  bedFilters: string[];
  bathFilters: string[];
  locFilters: string[];
  catFilters: string[];
  nameFilters: [string, string][];
  resortFilters: [string, string][];
  filterProperties: (
    beds: string | null,
    baths: string | null,
    loc: string | null,
    cat: string | null,
    name: string | null,
    resort: string | null,
    startDate: Moment | null,
    endDate: Moment | null
  ) => void;
};

const Filters = ({
  originalStartDate,
  originalEndDate,
  bedFilters,
  bathFilters,
  locFilters,
  catFilters,
  nameFilters,
  resortFilters,
  filterProperties,
}: Props) => {
  const [startDate, setStartDate] = useState<Moment | null>(originalStartDate);
  const [endDate, setEndDate] = useState<Moment | null>(originalEndDate);
  const [beds, setBeds] = useState<string | null>(null);
  const [baths, setBaths] = useState<string | null>(null);
  const [loc, setLoc] = useState<string | null>(null);
  const [cat, setCat] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [resort, setResort] = useState<string | null>(null);

  const [modalOpen, setModalOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');

  const openCalendarModal = () => {
    setModalOpen(true);
  };

  const closeCalendarModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    setStartDate(originalStartDate);
    setEndDate(originalEndDate);
  }, [originalEndDate, originalStartDate]);

  useEffect(() => {
    filterProperties(beds, baths, loc, cat, name, resort, startDate, endDate);
  }, [
    baths,
    beds,
    cat,
    loc,
    filterProperties,
    startDate,
    endDate,
    name,
    resort,
  ]);

  return (
    <>
      <div className="widget-property-filter-container">
        <div className="widget-property-filter filter-start-date">
          <TextInput
            readOnly
            placeholder="Start Date"
            value={startDate?.format('MMM DD, YYYY') ?? ''}
            onClick={openCalendarModal}
          />
        </div>
        <div className="widget-property-filter filter-end-date">
          <TextInput
            readOnly
            placeholder="End Date"
            value={endDate?.format('MMM DD, YYYY') ?? ''}
            onClick={openCalendarModal}
          />
        </div>
        <div className="widget-property-filter filter-beds">
          <Select
            clearable
            searchable
            placeholder="Bedrooms"
            maxDropdownHeight={280}
            data={bedFilters}
            value={beds}
            onChange={setBeds}
            dropdownPosition={'bottom'}
          />
        </div>
        <div className="widget-property-filter filter-baths">
          <Select
            clearable
            searchable
            placeholder="Bathrooms"
            maxDropdownHeight={280}
            data={bathFilters}
            value={baths}
            onChange={setBaths}
            dropdownPosition={'bottom'}
          />
        </div>
        <div className="widget-property-filter filter-location">
          <Select
            clearable
            searchable
            placeholder="Location"
            maxDropdownHeight={280}
            data={locFilters}
            value={loc}
            onChange={setLoc}
            dropdownPosition={'bottom'}
          />
        </div>
        <div className="widget-property-filter filter-category">
          <Select
            clearable
            searchable
            placeholder="Category"
            maxDropdownHeight={280}
            data={catFilters}
            value={cat}
            onChange={setCat}
            dropdownPosition={'bottom'}
          />
        </div>

        <div className="widget-property-filter filter-name">
          <Select
            clearable
            searchable
            placeholder="Name"
            maxDropdownHeight={280}
            data={nameFilters.map(([value, label]) => ({
              value,
              label,
            }))}
            value={name}
            onChange={setName}
            dropdownPosition={'bottom'}
          />
        </div>
        <div className="widget-property-filter filter-resort">
          <Select
            clearable
            searchable
            placeholder="Resort"
            maxDropdownHeight={280}
            data={resortFilters.map(([value, label]) => ({
              value,
              label,
            }))}
            value={resort}
            onChange={setResort}
            dropdownPosition={'bottom'}
          />
        </div>
      </div>
      <Modal
        opened={modalOpen}
        onClose={closeCalendarModal}
        size="auto"
        fullScreen={isMobile}
        styles={{
          modal: { display: 'flex', flexDirection: 'column' },
          body: { flex: '1' },
        }}
      >
        <Calendar
          isMobile={isMobile}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          closeModal={closeCalendarModal}
        />
      </Modal>
    </>
  );
};

export default Filters;
