import PropertySearch from './components/PropertySearch';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';

function App() {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <ModalsProvider>
        <PropertySearch />
      </ModalsProvider>
    </MantineProvider>
  );
}

export default App;
