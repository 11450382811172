import React, { useState } from 'react';
import moment, { Moment } from 'moment';

import 'react-dates/initialize';
import {
  DayPickerRangeController,
  FocusedInputShape,
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import '../styles/calendar.css';
import { clampCalendarMonth, getNoon } from '../utils/dates';
import CalendarInfo from './CalendarInfo';

export type Props = {
  isMobile: boolean;
  startDate: Moment | null;
  endDate: Moment | null;
  setStartDate: React.Dispatch<React.SetStateAction<Moment | null>>;
  setEndDate: React.Dispatch<React.SetStateAction<Moment | null>>;
  closeModal: () => void;
  searchClick?: () => void;
};

const Calendar = ({
  isMobile,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  closeModal,
  searchClick,
}: Props) => {
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape>(
    startDate ? 'endDate' : 'startDate'
  );

  const minDate = moment().startOf('month').hour(12);

  const renderDayContents = (date: Moment) => {
    return <div className="CalendarDayInner">{date.format('D')}</div>;
  };

  const onDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const clearDates = () => {
    setStartDate(null);
    setEndDate(null);
    setFocusedInput('startDate');
  };

  const isOutsideRange = (date: Moment) => {
    return (
      !isInclusivelyAfterDay(date, getNoon()) ||
      !isInclusivelyBeforeDay(
        date,
        moment(minDate).add(14, 'months').endOf('month')
      )
    );
  };

  return (
    <div
      className="Calendar__container"
      style={{ display: 'flex', height: '100%', flexDirection: 'column' }}
    >
      {isMobile && (
        <CalendarInfo
          clearDates={clearDates}
          closeModal={closeModal}
          searchClick={searchClick}
        />
      )}
      <div
        style={{
          height: isMobile ? '100px' : '375px',
          flexGrow: 1,
        }}
      >
        <DayPickerRangeController
          //required
          startDate={startDate}
          endDate={endDate}
          onDatesChange={onDatesChange}
          focusedInput={focusedInput}
          onFocusChange={(focusedInput: FocusedInputShape | null) => {
            setFocusedInput(focusedInput ?? 'startDate');
          }}
          //
          hideKeyboardShortcutsPanel
          keepOpenOnDateSelect
          transitionDuration={0}
          noBorder
          //
          initialVisibleMonth={() => {
            if (isMobile) return moment();
            if (startDate && endDate)
              return clampCalendarMonth(minDate, endDate);
            if (startDate) return clampCalendarMonth(minDate, startDate);
            return moment();
          }}
          minDate={moment(minDate)}
          maxDate={moment(minDate).add(14, 'months').endOf('month')}
          //
          orientation={isMobile ? 'verticalScrollable' : 'horizontal'}
          daySize={isMobile ? 35 : 45}
          numberOfMonths={isMobile ? 15 : 2}
          //
          renderDayContents={renderDayContents}
          isOutsideRange={isOutsideRange}
          // mobile
          noNavButtons={isMobile}
        />
      </div>
      {!isMobile && (
        <CalendarInfo
          clearDates={clearDates}
          closeModal={closeModal}
          searchClick={searchClick}
        />
      )}
    </div>
  );
};

export default Calendar;
