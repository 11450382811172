export type Props = {
  clearDates: () => void;
  closeModal: () => void;
  searchClick?: () => void;
};

const CalendarInfo = ({ clearDates, closeModal, searchClick }: Props) => {
  const handleConfirm = () => {
    closeModal();
    if (searchClick) searchClick();
  };

  return (
    <div className="Calendar__bottom_info">
      <div></div>
      <button className="Calendar__clear_text" onClick={clearDates}>
        Clear dates
      </button>
      <button className="Calendar__confirm_button" onClick={handleConfirm}>
        Confirm dates
      </button>
    </div>
  );
};

export default CalendarInfo;
