import { Carousel } from '@mantine/carousel';
import Autoplay from 'embla-carousel-autoplay';
import { useRef } from 'react';
import { IProperty } from '../utils/types';

export type Props = {
  property: IProperty;
};

const Property = ({ property }: Props) => {
  const autoplay = useRef(Autoplay({ delay: 10_000 }));

  return (
    <div className="searching-property-container">
      <div className="searching-property-carousel" style={{ display: 'flex' }}>
        <Carousel
          align="center"
          withIndicators
          loop
          height="100%"
          sx={{ flex: 1, width: '100%' }}
          plugins={[autoplay.current]}
          onMouseEnter={autoplay.current.stop}
          onMouseLeave={autoplay.current.reset}
        >
          <Carousel.Slide>
            <img
              className="searching-property-carousel-photo"
              src={property.htppostdir + property.phot01}
            />
          </Carousel.Slide>
          <Carousel.Slide>
            <img
              className="searching-property-carousel-photo"
              src={property.htppostdir + property.phot02}
            />
          </Carousel.Slide>
          <Carousel.Slide>
            <img
              className="searching-property-carousel-photo"
              src={property.htppostdir + property.phot03}
            />
          </Carousel.Slide>
        </Carousel>
      </div>
      <div className="searching-property-info">
        <div className="searching-property-name">
          <a href={`/property-detail/?propid=${property.propid}`}>
            {property.shortdesc}
          </a>
        </div>
        <div className="searching-property-details">
          <div className="searching-property-beds">
            Bedrooms {property.numbedrms}
          </div>
          <div className="searching-property-baths">
            Bathrooms {property.numbaths}
          </div>
          <div className="searching-property-sleeps">
            Sleeps {property.maxsleeps}
          </div>
        </div>
        <div className="searching-property-rates">{property.raterange}</div>
      </div>
    </div>
  );
};

export default Property;
