import moment, { Moment } from 'moment';

const getNoon = (date?: string) => {
  if (date) {
    return moment(date, 'YYYYMMDD').startOf('day').hour(12);
  }
  return moment().startOf('day').hour(12);
};

const getDayDifference = (startDate: Moment, endDate: Moment) => {
  return endDate.diff(startDate, 'days');
};

const validateRange = (
  minDate: Moment,
  startDate: Moment,
  endDate: Moment,
  avlist: string
) => {
  const startIndex = getDayDifference(minDate, startDate);
  const endIndex = getDayDifference(minDate, endDate);
  const numDays = endIndex - startIndex + 1;
  const regex = RegExp(
    `^A{${numDays}}$|^A{${numDays - 1}}(?![aA])[a-zA-Z]$`,
    'gm'
  );
  const availabilityString = avlist.slice(startIndex, endIndex + 1);
  return regex.test(availabilityString);
};

const clampCalendarMonth = (minDate: Moment, date: Moment) => {
  const monthDiff = date.diff(minDate, 'months');
  if (monthDiff >= 14) {
    return moment(date).subtract(1, 'month');
  }
  return date;
};

export { getDayDifference, getNoon, validateRange, clampCalendarMonth };
