import { Loader } from '@mantine/core';
import moment from 'moment';
import { Moment } from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { fetchAndProcess } from '../utils/data';
import { validateRange } from '../utils/dates';
import { IProperty } from '../utils/types';
import Filters from './Filters';
import PaginatedProperties from './PaginatedProperties';

export type Props = {
  originalStartDate: Moment | null;
  originalEndDate: Moment | null;
};

const InjectedContainer = ({ originalStartDate, originalEndDate }: Props) => {
  const [activePage, setActivePage] = useState(1);

  const [loading, setLoading] = useState(true);
  const [rawProperties, setRawProperties] = useState<IProperty[]>([]);
  const [filteredProperties, setFilteredProperties] = useState<IProperty[]>([]);
  const [bedFilters, setBedFilters] = useState<string[]>([]);
  const [bathFilters, setBathFilters] = useState<string[]>([]);
  const [locFilters, setLocFilters] = useState<string[]>([]);
  const [catFilters, setCatFilters] = useState<string[]>([]);
  const [nameFilters, setNameFilters] = useState<[string, string][]>([]);
  const [resortFilters, setResortFilters] = useState<[string, string][]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {
          finalProperties,
          bedFilters,
          bathFilters,
          locFilters,
          catFilters,
          nameFilters,
          resortFilters,
        } = await fetchAndProcess();
        setRawProperties(finalProperties);
        setFilteredProperties(finalProperties);
        setBedFilters(bedFilters);
        setBathFilters(bathFilters);
        setLocFilters(locFilters);
        setCatFilters(catFilters);
        setNameFilters(nameFilters);
        setResortFilters(resortFilters);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const filterProperties = useCallback(
    (
      beds: string | null,
      baths: string | null,
      loc: string | null,
      cat: string | null,
      name: string | null,
      resort: string | null,
      startDate: Moment | null,
      endDate: Moment | null
    ) => {
      const newProps = rawProperties.filter((prop) => {
        if (beds && parseFloat(prop.numbedrms) < parseFloat(beds)) return false;
        if (baths && parseFloat(prop.numbaths) < parseFloat(baths))
          return false;
        if (loc && prop.proplocnam !== loc) return false;
        if (cat && prop.propcatnam !== cat) return false;
        if (name && prop.shortname !== name) return false;
        if (resort && prop.propsiteid !== resort) return false;
        if (startDate && endDate) {
          const minDate = moment().startOf('month').hour(12);
          return validateRange(minDate, startDate, endDate, prop.avlist);
        }
        return true;
      });
      setFilteredProperties(newProps);
      setActivePage(1);
    },
    [rawProperties]
  );

  if (loading) {
    return (
      <div className="custom-loader-container">
        <div className="custom-loader">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <>
      <Filters
        originalStartDate={originalStartDate}
        originalEndDate={originalEndDate}
        bedFilters={bedFilters}
        bathFilters={bathFilters}
        locFilters={locFilters}
        catFilters={catFilters}
        nameFilters={nameFilters}
        resortFilters={resortFilters}
        filterProperties={filterProperties}
      />
      <div className="result-container">
        {filteredProperties.length ? (
          <PaginatedProperties
            properties={filteredProperties}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        ) : (
          <div className="result-container empty-search">
            No properties found.
          </div>
        )}
      </div>
      <div className="widget-map-container"></div>
    </>
  );
};

export default InjectedContainer;
