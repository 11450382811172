import { Moment } from 'moment';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import Form from './Form';
import InjectedContainer from './InjectedContainer';
import { MantineProvider } from '@mantine/core';

const PropertySearch = () => {
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);

  const searchClick = () => {
    const regularContent = document.getElementById('regular-content');
    if (regularContent) {
      regularContent.style.display = 'none';
      regularContent.classList.add('hidden');
    } else {
      console.error('element with id "regular-content" not found');
    }
    ReactDOM.render(
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <InjectedContainer
          originalStartDate={startDate}
          originalEndDate={endDate}
        />
      </MantineProvider>,
      document.getElementById('search-result')
    );
  };

  return (
    <>
      <Form
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        searchClick={searchClick}
      />
      {/* Development divs */}
      {/* <div id="regular-content">Hello from Regular</div>
      <div id="search-result"></div> */}
    </>
  );
};

export default PropertySearch;
