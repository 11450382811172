import axios from 'axios';
import { getNoon } from './dates';
import {
  ApiAvailability,
  ApiProperty,
  ApiRate,
  Fee,
  IAvailability,
  IProperty,
  IRate,
  ProcessedData,
  RateBreakdown,
} from './types';

async function fetchAndProcess(): Promise<ProcessedData> {
  const { properties, availabilities, rates } = await fetchApiData();

  const filteredProperties = properties.filter(
    (property) => property.propgrpid === '1006' || property.propgrpid === '1012'
  );

  const processedData = processData(filteredProperties, availabilities, rates);
  return processedData;
}

const processData = (
  properties: ApiProperty[],
  availabilities: ApiAvailability[],
  rates: ApiRate[]
) => {
  const availabilitiesObject = availabilities.reduce(
    (obj: IAvailability, item: ApiAvailability) => {
      const { propid, ...data } = item;
      obj[propid] = data;
      return obj;
    },
    {}
  );
  const ratesObject = rates.reduce((obj: IRate, item: ApiRate) => {
    const { propid, ...data } = item;
    obj[propid] = data;
    return obj;
  }, {});

  const finalProperties: IProperty[] = [];
  const bedFilters = new Set<string>();
  const bathFilters = new Set<string>();
  const locFilters = new Set<string>();
  const catFilters = new Set<string>();
  const nameFilters = new Map<string, string>();
  const resortFilters = new Map<string, string>();

  for (let i = 0; i < properties.length; i++) {
    const property = properties[i];
    if (!availabilitiesObject[property.propid]) {
      continue;
    }
    if (!ratesObject[property.propid]) {
      continue;
    }
    const stdPeople = parseInt(property.stdpersons);
    const maxPeople = parseInt(property.maxpersons);

    bedFilters.add(property.numbedrms);
    bathFilters.add(property.numbaths);
    locFilters.add(property.proplocnam);
    catFilters.add(property.propcatnam);
    nameFilters.set(
      property.shortname,
      `${property.propname} (${property.shortname})`
    );
    resortFilters.set(property.propsiteid, property.propsitnam);

    finalProperties.push({
      ...property,
      ...availabilitiesObject[property.propid],
      ...ratesObject[property.propid],
      feelist: processFees(property),
      ratelist: processRates(ratesObject[property.propid]),
      minntslist: availabilitiesObject[property.propid].minntslist.split(','),
      stdPeople: isNaN(stdPeople) ? 0 : stdPeople,
      maxPeople: isNaN(maxPeople) ? 0 : maxPeople,
    });
  }
  return {
    finalProperties,
    bedFilters: Array.from(bedFilters).sort(),
    bathFilters: Array.from(bathFilters).sort(),
    locFilters: Array.from(locFilters).sort(),
    catFilters: Array.from(catFilters).sort(),
    nameFilters: Array.from(nameFilters).sort(),
    resortFilters: Array.from(resortFilters).sort((a, b) =>
      a[1].toUpperCase().localeCompare(b[1].toUpperCase())
    ),
  };
};

const fetchApiData = async () => {
  const apiMeta = document.head.querySelector('[property~=api-base][content]');

  if (!apiMeta || !(apiMeta instanceof HTMLMetaElement)) {
    throw 'API meta tag not found or invalid';
  }

  const apiBase = apiMeta.content;

  const { data: properties }: { data: ApiProperty[] } = await axios.get(
    `${apiBase}fetch/properties/json`,
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    }
  );
  const { data: availabilities }: { data: ApiAvailability[] } = await axios.get(
    `${apiBase}fetch/availability/json`,
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    }
  );
  const { data: rates }: { data: ApiRate[] } = await axios.get(
    `${apiBase}fetch/rates/json`,
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    }
  );

  return { properties, availabilities, rates };
};

const processFees = (property: ApiProperty): Fee[] => {
  if (typeof property.feelist === 'string') {
    const fees = property.feelist
      .split('|')
      .map((fee) => fee.split('~'))
      .map((fee) => {
        const [salesTax, lodgeTax, processingFee, feeType] = fee[5].split('');
        const hasStartDate = fee[6].length && fee[6].includes('Ckin');
        let minStartDate = null;
        let maxStartDate = null;
        if (hasStartDate) {
          const regex = RegExp('\\d{8}', 'gm');
          const dates: string[] = [];
          let matches;
          while ((matches = regex.exec(fee[6])) !== null) {
            if (matches.index === regex.lastIndex) {
              regex.lastIndex++;
            }
            matches.forEach((match) => {
              dates.push(match);
            });
          }
          minStartDate = getNoon(dates[0]);
          maxStartDate = getNoon(dates[1]);
        }
        return {
          id: fee[7],
          name: fee[0],
          amount: parseFloat(fee[1]),
          isFixed: fee[2] === '00',
          isPercentRent: fee[2] === '01',
          isPercentTotal: fee[2] === '02',
          isNightly: fee[2] === '08',
          isPerExtraPersonNightly: fee[2] === '09',
          applySalesTax: salesTax === 'Y',
          applyLodgeTax: lodgeTax === 'Y',
          applyProcessingFee: processingFee === 'Y',
          isDefault: feeType === 'D' || feeType === 'B',
          isOptIn: feeType === 'I',
          isOptOut: feeType === 'O' || feeType === 'B',
          minStartDate,
          maxStartDate,
        };
      });
    return fees;
  }
  return [];
};

const processRates = (rate: Omit<ApiRate, 'propid'>): RateBreakdown[] => {
  if (typeof rate.ratelist === 'string') {
    const processedRates = rate.ratelist
      .split('|')
      .map((rate) => rate.split('~'))
      .map((rate) => {
        return {
          startDate: getNoon(rate[1]),
          endDate: getNoon(rate[2]),
          dayRate: parseFloat(rate[3].split(':')[1]),
          weekRate: parseFloat(rate[4].split(':')[1]),
          monthRate: parseFloat(rate[5].split(':')[1]),
        };
      });
    return processedRates;
  }
  return [];
};

export { fetchAndProcess };
